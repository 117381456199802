import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { UnauthorizedComponent } from './components/error-screen/401-error/unauthorized.component';
import { Error503Component } from './components/error-screen/503-error/error503.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'chat',
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { unique: true },
  },
  { path: 'invalid', component: Error503Component },
  {
    path: 'choose-your-gpt',
    loadChildren: () =>
      import('./components/choose-your-gpt/choose-your-gpt.module').then(
        (m) => m.ChooseYourGPTModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./terms-of-use-page/terms-of-use-page.module').then(
        (m) => m.TermsOfUsePageModule
      ),
  },
  {
    path: 'privacy-notice',
    loadChildren: () =>
      import('./privacy-notice-page/privacy-notice-page.module').then(
        (m) => m.PrivacyNoticePageModule
      ),
  },
  {
    path: 'cookie-notice',
    loadComponent: () =>
      import('./cookie-notice-page/cookie-notice-page.component').then(
        (c) => c.CookieNoticePageComponent
      ),
  },
  { path: '**', redirectTo: 'chat' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
