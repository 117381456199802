import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IChatReducer } from './chat.state';

export const chatKey = 'chat';

export const selectChat = createFeatureSelector<IChatReducer>(chatKey);

export const selectConversationToken = createSelector(
  selectChat,
  (state) => state.conversationToken
);

export const selectConnected = createSelector(
  selectChat,
  (state) => state.connected
);

export const selectMessages = createSelector(
  selectChat,
  (state) => state.messages
);

export const selectHasMessages = createSelector(
  selectChat,
  (state) => state.messages.length > 0
);

export const selectHasAttachedFile = createSelector(
  selectChat,
  (state) => state.hasAttachedFile
);
