<div id="cookie-banner" class="cookie-modal">
  <div
    class="cookie-modal__content"
    [ngClass]="{
      hidden: hasShownCookieBanner
    }"
  >
    <div class="cookie-modal__title">
      <h2>Cookie Settings</h2>
      <button (click)="dontShowBannerAnymore()">X</button>
    </div>
    <div class="cookie-modal__text-container">
      <p class="cookie-modal__text">
        For the Tool to function and to allow you to log-in/authenticate, we use
        strictly necessary cookies. For more information, please read the
        <a routerLink="/cookie-notice"> cookie policy </a>.
      </p>
    </div>

    <div class="cookie-modal__button-container">
      <button
        (click)="onAcceptClick()"
        id="accept-cookie"
        class="cookie-modal__button"
      >
        Ok
      </button>
    </div>
  </div>
</div>
