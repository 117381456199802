export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: 'f69bb773-2f59-4b48-a5dc-821a0d40e9a3',
  },
  SOCKET_BACKEND_URL:
    'https://stellaracapubchatprod.purplebush-016162df.eastus.azurecontainerapps.io',
  BACKEND_URL:
    'https://stellaracapubapiprod.purplebush-016162df.eastus.azurecontainerapps.io',
  APIM_SUBSCRIPTION_KEY: 'c3943a06245f4c39ad97e7dc7f3583bf',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: 'b639beb7-6ade-40e2-a088-a434fc28ac86',
    CONNECTION_STRING:
      'InstrumentationKey=b639beb7-6ade-40e2-a088-a434fc28ac86;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  },
};
