import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISidebarReducer } from './sidebar.state';

export const sidebarKey = 'sidebar';

export const selectSidebar = createFeatureSelector<ISidebarReducer>(sidebarKey);

export const selectExpanded = createSelector(
  selectSidebar,
  (state) => state.expanded
);
