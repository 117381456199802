import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    getUserDataRequest: emptyProps(),
    getUserDataSuccess: props<{
      id: string;
      name: string;
      email: string;
      accessToken: string;
    }>(),
    getUserPhotoSuccess: props<{ photo: string }>(),
    getUserPhotoFailure: props<{ photo: string }>(),
  },
});
