import { createReducer, on } from '@ngrx/store';
import { IAuthReducer } from './auth.store';
import { AuthActions } from './auth.actions';

const initialState: IAuthReducer = {
  authenticated: false,
  user: {
    accessToken: '',
    email: '',
    id: '',
    name: '',
    photo: '',
  },
};

export const authReducer = createReducer(
  initialState,
  on(
    AuthActions.getUserDataSuccess,
    (state, { accessToken, email, id, name }) => ({
      ...state,
      authenticated: true,
      user: {
        ...state.user,
        accessToken,
        email,
        id,
        name,
      },
    })
  ),
  on(AuthActions.getUserPhotoSuccess, (state, { photo }) => ({
    ...state,
    user: { ...state.user, photo },
  })),
  on(AuthActions.getUserPhotoFailure, (state, { photo }) => ({
    ...state,
    user: { ...state.user, photo },
  }))
);
