<body>
  <div class="stellar-header">
    <div class="abi-logo">
      <img src="assets/images/logo_abinbev.png" alt="" />
    </div>
  </div>
  <div class="container">
    <div class="error503-page">
      <div class="image-container">
        <div class="error-image-container">
          <img src="assets/images/Pose_Angry.png" class="error-image" alt="" />
        </div>
      </div>
      <div class="error-container">
        <div class="error-content-container">
          <h1 class="error-message">
            {{ 'COMPONENTS.ERROR_SCREEN.503.TITLE' | translate }}
          </h1>
          <p>
            {{ 'COMPONENTS.ERROR_SCREEN.503.DESCRIPTION' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</body>
