import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IAuthReducer } from './auth.store';

export const authKey = 'auth';

export const selectAuth = createFeatureSelector<IAuthReducer>(authKey);

export const selectUserId = createSelector(
  selectAuth,
  (state) => state.user.id
);

export const selectAccessToken = createSelector(
  selectAuth,
  (state) => state.user.accessToken
);

export const selectUserName = createSelector(
  selectAuth,
  (state) => state.user.name
);
