import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IConversation } from 'src/app/core/socket/chat-history.service';

export const ChatActions = createActionGroup({
  source: 'Chat Component',
  events: {
    connected: emptyProps(),
    createRoom: props<{ conversationToken?: string }>(),
    createdRoom: props<{ conversationToken: string }>(),
    setSelectedSuggestion: props<{ suggestion: string }>(),
    sendMessageRequest: props<{ message: string }>(),
    sendMessageSuccess: emptyProps(),
    conversationCompleted: props<{
      message: string;
      conversationToken: string;
      totalTokens: number;
      fromInternet: boolean;
      question_id: string;
      prompt: string;
    }>(),
    conversationWithError: emptyProps(),
    messageReceived: props<{ chunk: string }>(),
    getConversationByTokenRequest: props<{ conversationToken: string }>(),
    getConversationByTokenSuccess: props<{ conversation: IConversation[] }>(),
    resetConversation: emptyProps(),
    botTypingFinished: emptyProps(),
    submitFeedbackRequest: props<{
      feedback: {
        liked: boolean;
        rating?: string[];
        comment?: string;
        question_id: string;
        prompt: string;
        answer: string;
      };
    }>(),
    submitFeedbackSuccess: emptyProps(),
    stopTyping: emptyProps(),
    stopTypingDone: emptyProps(),
    isUploadingFile: emptyProps(),
    hasAttachedFile: props<{ hasFile: boolean }>(),
    uploadCompleted: emptyProps(),
    reset: emptyProps(),
  },
});
