import { createReducer, on } from '@ngrx/store';
import { ChatActions } from './chat.actions';
import { IChatReducer, IMessageInfo, IMessages } from './chat.state';
import { SidebarActions } from 'src/app/components/header/sidebar/state/sidebar.actions';

function createMessageObj(text: string, info?: IMessageInfo): IMessages {
  return {
    bot: !!info,
    text,
    info: info,
  };
}

export const initialState: IChatReducer = {
  showWelcomePage: true,
  showConversation: false,
  isSending: false,
  isBotTyping: false,
  connected: false,
  selectedSuggestion: '',
  suggestions: [
    {
      title: 'COL1',
      texts: ['OPT1', 'OPT2', 'OPT3'],
      cursor: true,
      materialIcon: 'light_mode',
    },
    {
      title: 'COL2',
      texts: ['OPT1', 'OPT2', 'OPT3'],
      cursor: false,
      materialIcon: 'bolt',
    },
    {
      title: 'COL3',
      texts: ['OPT1', 'OPT2', 'OPT3'],
      cursor: false,
      materialIcon: 'warning',
    },
  ],
  messages: [],
  conversationToken: '',
  isLoadingBotMessage: false,
  hasAttachedFile: false,
  isUploadingFile: false,
};

export const chatReducer = createReducer<IChatReducer>(
  initialState,
  on(
    ChatActions.connected,
    (state): IChatReducer => ({
      ...state,
      connected: true,
    })
  ),
  on(
    ChatActions.createdRoom,
    (state, { conversationToken }): IChatReducer => ({
      ...state,
      conversationToken: conversationToken,
    })
  ),
  on(
    ChatActions.setSelectedSuggestion,
    (state, { suggestion }): IChatReducer => ({
      ...state,
      selectedSuggestion: suggestion,
    })
  ),
  on(ChatActions.sendMessageRequest, (state, { message }) => {
    return {
      ...state,
      showWelcomePage: false,
      showConversation: true,
      isSending: true,
      messages: [...state.messages, createMessageObj(message)],
    };
  }),
  on(ChatActions.sendMessageSuccess, (state): IChatReducer => {
    return {
      ...state,
      isSending: false,
      isLoadingBotMessage: true,
    };
  }),
  on(ChatActions.messageReceived, (state, { chunk }) => {
    const lastMessage = state.messages[state.messages.length - 1];

    if (lastMessage.bot) {
      const lastMessageCopy = { ...lastMessage };
      const messagesWithoutLastMessage = state.messages.filter(
        (_, i) => state.messages.length !== i + 1
      );

      lastMessageCopy.text = lastMessage.text + chunk;

      return {
        ...state,
        isBotTyping: true,
        isLoadingBotMessage: false,
        messages: [...messagesWithoutLastMessage, lastMessageCopy],
      };
    } else {
      return {
        ...state,
        isBotTyping: true,
        isLoadingBotMessage: false,
        messages: [
          ...state.messages,
          createMessageObj('', {
            fromInternet: false,
            totalTokens: 0,
            prompt: '',
            question_id: '',
            feedbackLike: false,
            hasFeedback: false,
          }),
        ],
      };
    }
  }),
  on(
    ChatActions.conversationCompleted,
    (
      state,
      { conversationToken, fromInternet, totalTokens, question_id, prompt }
    ) => {
      const lastMessageCopy = state.messages[state.messages.length - 1];
      const messagesWithoutLastMessage = state.messages.filter(
        (_, i) => state.messages.length !== i + 1
      );

      return {
        ...state,
        isBotTyping: false,
        conversationToken,
        messages: [
          ...messagesWithoutLastMessage,
          createMessageObj(lastMessageCopy.text, {
            fromInternet,
            totalTokens,
            prompt,
            question_id,
            hasFeedback: false,
            feedbackLike: false,
          }),
        ],
      };
    }
  ),
  on(
    ChatActions.conversationWithError,
    (state): IChatReducer => ({
      ...state,
      isBotTyping: false,
      isLoadingBotMessage: false,
    })
  ),
  on(
    ChatActions.getConversationByTokenRequest,
    (state, { conversationToken }): IChatReducer => ({
      ...state,
      conversationToken,
    })
  ),
  on(ChatActions.getConversationByTokenSuccess, (state, { conversation }) => ({
    ...state,
    showConversation: true,
    showWelcomePage: false,
    messages: conversation.reduce<IMessages[]>((acc, conversation) => {
      return [
        ...acc,
        createMessageObj(conversation.prompt),
        createMessageObj(conversation.answer, {
          fromInternet: conversation.conversation_contexts.includes('INTERNET'),
          totalTokens: conversation.totalTokens,
          prompt: conversation.prompt,
          question_id: conversation.question_id,
          feedbackLike: !!conversation.feedbacks[0]?.liked,
          hasFeedback: !!conversation.feedbacks.length,
        }),
      ];
    }, []),
  })),
  on(
    ChatActions.resetConversation,
    (state): IChatReducer => ({
      ...state,
      showConversation: false,
      showWelcomePage: true,
      messages: [],
      conversationToken: '',
    })
  ),
  on(
    ChatActions.botTypingFinished,
    (state): IChatReducer => ({
      ...state,
      isBotTyping: false,
    })
  ),
  on(ChatActions.submitFeedbackRequest, (state, action): IChatReducer => {
    const feedbackLike = action.feedback.liked;
    const messageIndex = state.messages.findIndex(
      (message) => message.info?.question_id === action.feedback.question_id
    );

    if (messageIndex) {
      const messages = state.messages.map((message, index) => {
        if (index === messageIndex && message.info) {
          return createMessageObj(message.text, {
            ...message.info,
            feedbackLike,
            hasFeedback: true,
          });
        }
        return message;
      });

      return {
        ...state,
        messages,
      };
    }

    return state;
  }),
  on(
    ChatActions.stopTyping,
    (state): IChatReducer => ({
      ...state,
      isBotTyping: false,
      isLoadingBotMessage: false,
      conversationToken: '',
    })
  ),
  on(
    ChatActions.reset,
    (): IChatReducer => ({
      ...initialState,
      connected: true,
    })
  ),
  on(
    SidebarActions.deleteAllHistorySuccess,
    (state): IChatReducer => ({
      ...state,
      showWelcomePage: true,
      showConversation: false,
      messages: [],
      conversationToken: '',
    })
  ),
  on(
    ChatActions.hasAttachedFile,
    (state, { hasFile }): IChatReducer => ({
      ...state,
      hasAttachedFile: hasFile,
    })
  ),
  on(
    ChatActions.isUploadingFile,
    (state): IChatReducer => ({
      ...state,
      isUploadingFile: true,
    })
  ),
  on(
    ChatActions.uploadCompleted,
    (state): IChatReducer => ({
      ...state,
      isUploadingFile: false,
      hasAttachedFile: true,
    })
  )
);
