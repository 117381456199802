import { environment } from 'src/environments/environment';
const TENANT_ID = 'cef04b19-7776-4a94-b89b-375c77a8f936';

export const CONSTANTS = {
  GRAPH_URL: 'https://graph.microsoft.com/me',
  GRAPH_V1_URL: 'https://graph.microsoft.com/v1.0/me',
  USER_PHOTO_URL: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  AUTHORITY_URL: `https://login.microsoftonline.com/${TENANT_ID}`,
  AUTH_SCOPES: [`${environment.APP_REGISTRATION.CLIENT_ID}/.default`],
  GRAPH_SCOPES: ['user.read'],
};
