import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SidebarActions } from './sidebar.actions';
import { ChatHistoryService } from 'src/app/core/socket/chat-history.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import daysToNow from '../utils/daysToNow';
import { selectExpanded } from './sidebar.selectors';
import { ChatActions } from 'src/app/chat/state/chat.actions';
import { selectMessages } from 'src/app/chat/state/chat.selectors';

@Injectable()
export class SidebarEffects {
  constructor(
    private actions$: Actions,
    private chatHistoryService: ChatHistoryService,
    private router: Router,
    private store: Store
  ) {}

  fetchHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SidebarActions.requestHistory,
        SidebarActions.toggleExpanded,
        SidebarActions.close
      ),
      concatLatestFrom(() => this.store.select(selectExpanded)),
      mergeMap(([, expanded]) => {
        if (!expanded) {
          return this.chatHistoryService.getHistory().pipe(
            map((history) => {
              const historyWithDaysToNow = history.map((item) =>
                daysToNow(item)
              );

              return SidebarActions.requestHistorySuccess({
                history: historyWithDaysToNow,
              });
            }),
            catchError(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  });

  deleteHistoryItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SidebarActions.deleteHistoryItemRequest),
      mergeMap(({ conversationToken }) =>
        this.chatHistoryService
          .deleteConversationByToken(conversationToken)
          .pipe(
            map(() => {
              this.router.navigateByUrl('/chat');

              return SidebarActions.deleteHistoryItemSuccess({
                conversationToken,
              });
            }),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.conversationCompleted),
      concatLatestFrom(() => this.store.select(selectMessages)),
      map(([conversationCompletedPayload, messages]) => {
        const conversationWithDaysToNow = daysToNow({
          conversation_token: conversationCompletedPayload.conversationToken,
          title: messages[0].text,
          create_at: new Date().toString(),
        });

        return SidebarActions.updateHistory(conversationWithDaysToNow);
      })
    );
  });

  deleteAllHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SidebarActions.deleteAllHistoryRequest),
      mergeMap(() =>
        this.chatHistoryService.deleteAllHistory().pipe(
          map(() => {
            return SidebarActions.deleteAllHistorySuccess();
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
