import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, merge, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  switchMap,
  map,
  mergeMap,
} from 'rxjs/operators';
import { ChatHistoryService } from 'src/app/core/socket/chat-history.service';
import { SocketService } from 'src/app/core/socket/socket.service';
import { AutomaticLogoutService } from '../services/automatic-logout.service';
import { ChatActions } from './chat.actions';
import { FeedbackService } from 'src/app/core/socket/feedback.service';
import { selectUserId } from 'src/app/auth/state/auth.selectors';
import {
  selectConversationToken,
  selectHasAttachedFile,
} from './chat.selectors';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { Router } from '@angular/router';
import { SidebarActions } from 'src/app/components/header/sidebar/state/sidebar.actions';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private socketService: SocketService,
    private router: Router,
    private store: Store,
    private chatHistoryService: ChatHistoryService,
    private autoLogoutService: AutomaticLogoutService,
    private feedbackService: FeedbackService
  ) {}

  connect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      exhaustMap(() => {
        return this.socketService
          .start()
          .onConnected()
          .pipe(
            map(() => {
              return ChatActions.connected();
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  createdRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.resetConversation,
        ChatActions.reset,
        ChatActions.getConversationByTokenRequest,
        SidebarActions.deleteAllHistorySuccess
      ),

      switchMap((action) => {
        return this.socketService
          .createRoom((action as any)?.conversationToken)
          .onCreatedRoom()
          .pipe(
            map((conversationToken) => {
              this.router.navigate(['chat/', conversationToken]);

              return ChatActions.createdRoom({ conversationToken });
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.sendMessageRequest),
      concatLatestFrom(() => [
        this.store.select(selectUserId),
        this.store.select(selectConversationToken),
        this.store.select(selectHasAttachedFile),
      ]),
      exhaustMap(([action, userId, conversationToken, hasAttachedFile]) => {
        return of(
          this.socketService.sendMessage({
            conversationToken,
            prompt: action.message,
            userId,
            hasFile: hasAttachedFile,
          }),
          this.autoLogoutService.dispatchUserActiveEvent()
        ).pipe(
          map(() => {
            return ChatActions.sendMessageSuccess();
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  getMessagesFromBot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.sendMessageSuccess, ChatActions.stopTyping),
      switchMap((action) => {
        if (action.type === '[Chat Component] stopTyping') {
          return of(ChatActions.stopTypingDone());
        }

        return merge(
          this.socketService.onConversationCompleted(),
          this.socketService.onConversationTyping()
        ).pipe(
          map((res) => {
            if ('chunk' in res) {
              return ChatActions.messageReceived({ chunk: res.chunk });
            }

            return ChatActions.conversationCompleted({
              conversationToken: res.conversationToken,
              fromInternet: res.conversationContexts.includes('INTERNET'),
              message: res.answer,
              totalTokens: res.totalTokens,
              question_id: res.question_id,
              prompt: res.prompt,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  getConversationByToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.getConversationByTokenRequest),
      mergeMap((action) =>
        this.chatHistoryService
          .getConversationByToken(action.conversationToken)
          .pipe(
            map((conversation) => {
              this.autoLogoutService.dispatchUserActiveEvent();

              return ChatActions.getConversationByTokenSuccess({
                conversation,
              });
            }),
            catchError(() => EMPTY)
          )
      )
    );
  });

  submitFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.submitFeedbackRequest),
      concatLatestFrom(() => [
        this.store.select(selectUserId),
        this.store.select(selectConversationToken),
      ]),
      mergeMap(([action, user_id, conversation_token]) => {
        return this.feedbackService
          .submitFeedback({
            ...action.feedback,
            user_id,
            conversation_token,
          })
          .pipe(
            map(() => ChatActions.submitFeedbackSuccess()),
            catchError(() => EMPTY)
          );
      })
    );
  });
}
