import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface IHistory {
  conversation_token: string;
  title: string;
  create_at: string;
}

interface IFeedback {
  id: string;
  liked: boolean;
  created_at: string;
}

export interface IConversation {
  question_id: string;
  prompt: string;
  answer: string;
  created_at: string;
  conversation_contexts: string[];
  totalTokens: number;
  feedbacks: IFeedback[];
}

@Injectable({
  providedIn: 'root',
})
export class ChatHistoryService {
  constructor(private http: HttpClient) {}

  private url = `${environment.BACKEND_URL}/api/chat/history`;

  getHistory(): Observable<IHistory[]> {
    return this.http.get<IHistory[]>(this.url);
  }

  getConversationByToken(
    conversationToken: string
  ): Observable<IConversation[]> {
    return this.http.get<IConversation[]>(
      `${this.url}/${conversationToken}/messages`
    );
  }

  deleteConversationByToken(conversationToken: string): Observable<any> {
    return this.http.delete(`${this.url}/${conversationToken}`);
  }

  deleteAllHistory(): Observable<any> {
    return this.http.delete(`${this.url}`);
  }
}
