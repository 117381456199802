import { Component, Inject, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { AuthActions } from './auth/state/auth.actions';
import { CONSTANTS } from './auth/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  get authenticated(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private store: Store
  ) {}

  ngOnInit() {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(async () => {
        if (!this.authenticated) {
          await this.logIn();
        }
      });

    this.authService.handleRedirectObservable().subscribe({
      next: (res: AuthenticationResult) => {
        if (!this.authenticated) {
          this.authService.instance.setActiveAccount(res.account);

          this.dispatchUserDataSuccess(res);
        } else {
          this.authService
            .acquireTokenSilent({
              scopes: CONSTANTS.AUTH_SCOPES,
            })
            .subscribe((res) => {
              this.dispatchUserDataSuccess(res);
            });
        }
      },
      error: (error) => console.log(error),
    });
  }

  private dispatchUserDataSuccess(userData: AuthenticationResult) {
    const {
      accessToken,
      account: { name, username: email, localAccountId: id },
    } = userData;

    const pattern = /(\w+),\s+(\w+)/;
    const groupNames = (name || '').match(pattern);
    const userName = groupNames ? `${groupNames[2]} ${groupNames[1]}` : name;

    this.store.dispatch(
      AuthActions.getUserDataSuccess({
        accessToken,
        email,
        id,
        name: userName as string,
      })
    );
  }

  async logIn() {
    await this.authService.instance.loginRedirect(
      this.msalGuardConfig.authRequest as RedirectRequest
    );
  }
}
