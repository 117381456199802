import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-error503',
  templateUrl: './error503.component.html',
  styleUrls: ['./error503.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class Error503Component {
  reloadPage() {
    window.location.reload();
  }
}
