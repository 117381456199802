import { createReducer, on } from '@ngrx/store';
import { SidebarActions } from './sidebar.actions';
import { ISidebarReducer } from './sidebar.state';

export const initialState: ISidebarReducer = {
  history: [],
  expanded: false,
  selectedConversationToken: '',
  showDeleteButtons: false,
};

export const sidebarReducer = createReducer<ISidebarReducer>(
  initialState,
  on(
    SidebarActions.toggleExpanded,
    (state): ISidebarReducer => ({
      ...state,
      expanded: !state.expanded,
    })
  ),
  on(
    SidebarActions.close,
    (state): ISidebarReducer => ({
      ...state,
      expanded: false,
    })
  ),
  on(SidebarActions.requestHistorySuccess, (state, { history }) => ({
    ...state,
    history: history.map((i) => i).reverse(),
  })),
  on(
    SidebarActions.setShowDeleteButtons,
    (state, { showDeleteButtons }): ISidebarReducer => ({
      ...state,
      showDeleteButtons,
    })
  ),
  on(
    SidebarActions.deleteHistoryItemSuccess,
    (state, { conversationToken }) => {
      const history = state.history.filter(
        (historyItem) => historyItem.conversation_token !== conversationToken
      );

      return {
        ...state,
        showDeleteButtons: false,
        history,
      };
    }
  ),
  on(
    SidebarActions.setSelectedConversationToken,
    (state, { selectedConversationToken }): ISidebarReducer => ({
      ...state,
      selectedConversationToken,
    })
  ),
  on(
    SidebarActions.updateHistory,
    (state, { conversation_token, daysToNow, title }) => {
      const historyWithoutConversation = state.history.filter(
        (item) => item.conversation_token !== conversation_token
      );

      return {
        ...state,
        history: [
          {
            conversation_token,
            title,
            daysToNow,
          },
          ...historyWithoutConversation,
        ],
      };
    }
  ),
  on(
    SidebarActions.deleteAllHistorySuccess,
    (state): ISidebarReducer => ({
      ...state,
      history: [],
    })
  )
);
