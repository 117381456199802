import { NgModule } from '@angular/core';
import { CookieBannerComponent } from './cookie-banner.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CookieBannerComponent],
  imports: [CommonModule, RouterModule],
  exports: [CookieBannerComponent],
})
export class CookieBannerModule {}
